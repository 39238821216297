import React from "react"
import Game from '../components/game/Game'
import './App.css'
const App = () => {
        return (
            <div>
                <Game/>
            </div>
        )
}
export default App